import React from 'react'
import { TagCloud } from 'react-tagcloud'
import { Link } from 'react-router-dom'

const tags = [
  { value: 'React', link: 'https://react.dev/', color: 'text-sky-700', colorValue: '500', size: '4xl' },
  { value: 'JavaScript', link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript', color: 'text-yellow-400', size: '3xl' },
  { value: 'CSS', link: 'https://www.w3.org/Style/CSS/Overview.en.html', color: 'text-blue-600', size: '2xl' },
  { value: 'HTML', link: 'https://developer.mozilla.org/en-US/docs/Web/HTML', color: 'text-orange-700', size: '2xl' },
  { value: 'Node.js', link: 'https://nodejs.org/en', color: 'text-emerald-600', size: '3xl' },
  { value: 'Express.js', link: 'https://expressjs.com/', color: 'text-slate-500', size: 'xl' },
  { value: 'Python', link: 'https://www.python.org/', color: 'text-yellow-300', size: '2xl' },
  { value: 'Django', link: 'https://www.djangoproject.com/', color: 'text-emerald-500', size: '2xl' },
  { value: 'Stable Diffusion', link: 'https://www.mongodb.com/', color: 'text-violet-700', size: '4xl' },
  { value: 'Large Language Models', link: 'https://www.mongodb.com/', color: 'text-red-600', size: '3xl' },
  { value: 'Java', link: 'https://www.java.com/en/', color: 'text-blue-700', size: '2xl' },
  { value: 'Spring', link: 'https://spring.io/', color: 'text-emerald-400', size: '2xl' },
  { value: 'PostgreSQL', link: 'https://www.mongodb.com/', color: 'text-cyan-700', size: '2xl' },
  { value: 'Firebase', link: 'https://firebase.google.com/', color: 'text-orange-500', size: 'xl' },
  { value: 'Amazon AWS', link: 'https://www.mongodb.com/', color: 'text-emerald-300', size: 'xl' },
  { value: 'Heroku', link: 'https://www.mongodb.com/', color: 'text-purple-300', size: 'xl' },
  { value: 'Photoshop', link: 'https://www.mongodb.com/', color: 'text-blue-500', size: '2xl' },
  { value: 'Computer Vision', link: 'https://www.mongodb.com/', color: 'text-red-300', size: '2xl' },
  { value: 'Web3', link: 'https://www.mongodb.com/', color: 'text-slate-500', size: '2xl' }
]

const customRenderer = (tag) => {
  const link = tag.link
  return (
    <span className='inline-block'><Link to={link} className={`${tag.color}  p-2   text-${tag.size} `} style={{}}>
      {tag.value}
    </Link></span>
  )
}

const TagCloudComponent = () => {
  return (<>

        <div className="border-2 p-5 border-black rounded-l m-5">
        <h3 className='font-mono font-extrabold text-4xl border-b-4'>Technologies</h3>
        <p className='font-mono font-semibold text-xl' >Click to Find out more:</p>
      <TagCloud className="" tags={tags} minSize={12} maxSize={35} shuffle={true} renderer={customRenderer} />
      </div>

    </>)
}

export default TagCloudComponent
