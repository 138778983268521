/* eslint-disable react/prop-types */
import React from 'react'

const Card = ({ heading, subheading, image, content }) => {
  return (
    <div className="max-w-md mx-auto  bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
      <div className="md:flex">

        <div className="">
        <div className="md:flex-shrink-0">

            <img
className=""
src={image}
alt="Placeholder"
/>
        </div>
        <div className="p-8">
          <div className=" uppercase tracking-wide text-sm text-indigo-500 font-semibold">
            {heading}
          </div>
          <p className=" block mt-1 text-lg leading-tight font-medium text-black">
            {subheading}
          </p>
          <p className=" mt-2 text-gray-500">
            {content}
          </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
