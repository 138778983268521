/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Navbar from './Navbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import 'swiper/css'
import FadeIn from './FadeIn'
import Footer from './Footer'

export default function About () {
  const imgStyle = 'drop-shadow-xlg rounded-xl'
  return (<>

    <div className="sticky top-0 z-20 bg-black">
    <Navbar/>
    </div>

    <div className="absolute lg:top-[10%] lg:ml-32 sm:m-auto rounded-xl bg-black bg-opacity-50 p-2 z-10">
    <h1 className="text-3xl text-white font-bold">About Us</h1>
    </div>
    <div className="z-0">
    <img src="./comp1a.png" alt="server_room"/>
    </div>

    <FadeIn>
    <section className="flex flex-col md:flex-row my-20 lg:mx-20">
    <div className="md:w-1/2 my-auto">
      <Swiper
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        delay: 3500,
        disableOnInteraction: true
      }}
      modules={[Autoplay]}
    >
    <SwiperSlide><img className={imgStyle} src='./about1.png' alt="it_pro"/></SwiperSlide>
    <SwiperSlide><img className={imgStyle} src="./about2.png" alt="it_pro" /></SwiperSlide>
    <SwiperSlide><img className={imgStyle} src='./about3.png' alt="it_pro"/></SwiperSlide>
    <SwiperSlide><img className={imgStyle} src='./about4.png' alt="it_pro"/></SwiperSlide>
    <SwiperSlide><img className={imgStyle} src='./about5.png' alt="it_pro"/></SwiperSlide>
    </Swiper>
    </div>
            <div className="md:w-1/2 font-sans text-gray-800 leading-loose ">
              <h2 className="text-4xl font-bold mb-4">The Reactant Team</h2>
              <div className="text-xl text-justify">
              <div className="border-t border-gray-800 border-4 my-2"></div>
              <p className="text-lg mb-6 mt-5">At Reactant Technologies, we believe that every business deserves to have technology that meets their unique needs. That's why we specialize in providing custom solutions that help businesses thrive in the digital age.</p>
              <p className="text-lg mb-6">Our team of experienced professionals works closely with each client to understand their business goals and challenges. We then develop custom software, applications, and integrations that are tailored to each client's specific needs. Whether you need a custom CRM system, a mobile app, or an e-commerce platform, we have the expertise to deliver the right solution for your business.</p>
              <p className="text-lg mb-6">We pride ourselves on our commitment to quality and customer satisfaction. We take the time to understand each client's needs and provide ongoing support and maintenance to ensure their technology continues to meet their needs over time. Our team is always available to answer questions, provide updates, and make modifications as needed.</p>
              <p className="text-lg mb-6">At our company, we believe that technology should work for you, not the other way around. Let us help you take your business to the next level with customized technology solutions that are tailored to your unique needs. Contact us today to learn more about how we can help your business succeed.</p>
              </div>
            </div>
          </section>
          </FadeIn>
          <Footer/>
    </>)
}
