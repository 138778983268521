import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

// eslint-disable-next-line react/prop-types
const FadeIn = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1
  })

  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 50 }
  }

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={variants}
      transition={{ duration: 1 }}
    >
      {children}
    </motion.div>
  )
}

export default FadeIn
