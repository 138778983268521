import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ReactGA from 'react-ga4'
import CookieConsent from 'react-cookie-consent'

const root = ReactDOM.createRoot(document.getElementById('root'))
ReactGA.initialize('G-9EF283Q01J')
root.render(
  <React.StrictMode>
    <App />
    <CookieConsent buttonText="Accept">
        <span style={{ fontSize: '10px' }}>
          We use cookies on our website to give you the most relevant experience by remembering your
          preferences and repeat visits. By clicking “Accept”, you consent to the use of all the
          cookies. However, you may visit Cookie Settings to provide a controlled consent.
        </span>
      </CookieConsent>
  </React.StrictMode>
)
const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname
  })
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics)
