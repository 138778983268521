import React from 'react'
import Hero from './Hero'
import Navbar from './Navbar'
import FadeIn from './FadeIn'
import { Link } from 'react-router-dom'
import Footer from './Footer'

export default function Home () {
  return (<>

    <div style={{ backgroundImage: 'url(./hero1a.jpg)' }} className="bg-cover bg-center bg-no-repeat lg:h-screen">
    <Navbar sticky="top"/>
          <Hero/>
    </div>

      <section className="bg-gradient-to-b from-blue-100 to-green-100 font-sans text-gray-900 leading-loose">
        <div className="container mx-auto py-12">

          <FadeIn>
          <section className="flex flex-col md:flex-row md:justify-between lg:mb-8 lg:mr-4 md:m-1">

            <div className="md:w-1/2">

              <h2 className="text-3xl font-bold mb-4">Custom End-to-End Solutions</h2>
              <p className="text-xl text-justify">
              Whether you need custom IT Infrastructure, or Support, Software Development, Digital Media or any other technology solution, we have the expertise and experience to deliver results. With our custom solutions, you can streamline your operations and focus on what really matters - growing your business.
              </p>
              <p className="my-6"><Link to="/contact"className="text-green-700 text-2xl" >Click For a free Consultation Today{'>'}</Link></p>

            </div>

            <div className="md:w-1/2 ml-44">

              <img className=" drop-shadow-2xlg rounded-l" src="./edge2edge.png" alt="technology_everywhere" />

            </div>

          </section>
          </FadeIn>

          <FadeIn>
          <section className="flex flex-col md:flex-row md:justify-between">
            <div className="md:w-1/3 lg:mr-4 md:m-1">

              <h2 className="text-3xl font-bold mb-4">Cutting Edge Solutions</h2>
              <p className="text-xl text-justify">
              We use the latest technologies and industry best practices to ensure that your customized technology solution is secure, scalable, and easy to use. And we provide ongoing support and maintenance to ensure that your technology stays up-to-date and performs at its best.
              </p>
              <p className="my-6"><Link to="/services"className="text-green-700 text-xl" >Click to find out more about our Services Today{'>'}</Link></p>
            </div>
            <div className="md:w-1/3 m-1">

              <img className="drop-shadow-2xl" src="./it1.png" alt="Placeholder" />

            </div>
            <div className="md:w-1/3 m-1">

              <img className="drop-shadow-2xl" src="./it3.png" alt="Placeholder" />

            </div>

          </section>
          </FadeIn>
        </div>
      </section>
      <Footer/>
      </>
  )
}
