import React from 'react'
import Navbar from './Navbar'
import FadeIn from './FadeIn'
import TagCloudComponent from './tagCloud'
import Footer from './Footer'
import Card from './serviceCard'
export default function Services () {
  return (<>
    <div className=" bg-black">
    <Navbar/>
    </div>

    <div className="absolute lg:top-[10%] lg:ml-32 sm:m-auto rounded-xl bg-black bg-opacity-50 p-2 z-10">
    <h1 className="text-3xl text-white font-bold">Services</h1>
    </div>
    <div className="z-0">
    <img src="./comp3.png" alt="server_room"/>
    </div>

{/* <section className="mx-auto px-4 md:m-0 my-20 grid xl:grid-cols-3 gap-4 lg:grid-cols-1"> */}
<section className="container xl:mx-auto px-4 lg:px-8 xl:px-10 my-10">
<div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8">
    <div><Card heading="Digital Media" image="./services1.jpg"subheading="Take your digital media to the next level!" content="Our team of experienced designers and videographers use the latest industry-standard software, including Photoshop and After Effects, to create visually stunning content that will capture your audience's attention."></Card></div>
    <div><Card heading="It Services" image="./services2.jpg" subheading="Customized solutions that meet your unique business needs."content="Our team of experienced IT professionals is well-versed in hardware, servers, virtualization, and networking solutions."></Card></div>
    <div><Card heading="Web Development" image="./services3.jpg" subheading="Applications will be built to the highest standards."content="We specialize in using the latest technologies and industry best practices to create high-performance, scalable, and secure web applications."></Card></div>
</div>
</section>

    <section className="lg:mx-auto xl:mx-52 text-justify my-10">
        <div className="mx-auto p-8">
        <h2 className="text-justified font-bold text-3xl">We offer expertise in the following and more:</h2>
        <p className="text-justified text-xl py-4">We specialize in creating custom web solutions tailored to your business needs. Our team of expert developers has extensive experience in frontend and backend development, using the latest technologies such as React, JavaScript, and CSS.</p>

<p className="text-justified text-xl py-4">Our frontend development services will help you create an engaging user experience that will keep your customers coming back for more. We specialize in crafting custom interfaces that are not only beautiful but also user-friendly and intuitive.</p>

<p className="text-justified text-xl py-4">On the backend side, our developers have a deep understanding of server-side programming languages and frameworks, ensuring that your website or application runs smoothly and efficiently.</p>

<p className="text-justified text-xl py-4">Whether you need a brand-new website from scratch, a redesign of an existing site, or any other web development services, we have the expertise and experience to bring your vision to life.</p>
</div>
        <FadeIn>
        <TagCloudComponent />
        </FadeIn>
    </section>
<Footer/>
    </>)
}
