import React from 'react'
import { Link } from 'react-router-dom'

function Footer () {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto flex justify-between">
        <div>
          <h2 className="font-bold text-lg mb-2">Reactant Technologies LLC.</h2>
          <p className="text-gray-400 text-sm">&copy; 2023 Reactant Technologies LLC.</p>
        </div>
        <div>
          <h3 className="font-bold text-lg mb-2">Links</h3>
          <ul className="list-none text-gray-400 text-sm">
            <li className="mb-1"><Link to="/">Home</Link></li>
            <li className="mb-1"><Link to="/about">About</Link></li>
            <li className="mb-1"><Link to="/services">Services</Link></li>
            <li className="mb-1"><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
