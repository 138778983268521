import React from 'react'
import { Link } from 'react-router-dom'

function Hero () {
  return (

      <div className="rounded-xl md:mt-[35%] lg:mb-10 lg:mx-96 text-cyan-50 bg-black bg-opacity-40 px-5 pb-5">
        <h2 className="text-4xl font-bold mb-4 mt-96">Technology Built For you.</h2>
        <p className="text-lg mb-4">
        Our team of expert engineers and designers will work with you to creating tailored solutions to fit your unique requirements.
        </p>
        <Link to="./about">
        <button className="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded-xl">
          Learn More
        </button>
        </Link>
      </div>
  )
}

export default Hero
