
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from '@headlessui/react'
import { FiMenu, FiX } from 'react-icons/fi'

const navigation = [
  { name: 'Home', to: '/' },
  { name: 'About', to: '/about' },
  { name: 'Services', to: '/services' },
  { name: 'Contact', to: '/contact' }
]

function Navbar () {
  // const [isOpen, setIsOpen] = useState(false);
  const [navColor, setNavColor] = useState('bg-black bg-opacity-[5%]')

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setNavColor('bg-black sticky top-0 z-50')
      } else {
        setNavColor('bg-black bg-opacity-[5%]')
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Menu as="nav" className={navColor}>
      {({ open }) => (
        <>
          {/* <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"> */}
          <div className=" mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <Link
                  to="/"
                  className="flex-shrink-0 text-white text-2xl font-bold"
                >
                  <img className="h-16 w-16 m-2 inline-block" src="./path2.svg" alt="logo"></img>
                  <span className="font-mono">Reactant Technologies</span>
                </Link>
              </div>
              <div className="hidden lg:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className="text-white hover:bg-blue px-3 py-2 rounded-md text-sm font-medium"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>

              <div className="-mr-2 flex lg:hidden">
                <Menu.Button className="inline-flex items-center justify-center p-2 rounded-md text-green-100 hover:text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open
                    ? (
                    <FiX className="block h-6 w-6" aria-hidden="true" />
                      )
                    : (
                    <FiMenu className="block h-6 w-6" aria-hidden="true" />
                      )}
                </Menu.Button>
              </div>
            </div>
          </div>

          <Menu.Items className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.to}
                  className="text-green-100 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Menu.Items>

        </>
      )}
    </Menu>
  )
}

export default Navbar
