import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import Navbar from './components/Navbar'
import Services from './components/Services'
import Contact from './components/Contact'

function App () {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/about',
      element: <About />
    },
    {
      path: '/services',
      element: <Services />
    },
    {
      path: '/contact',
      element: <Contact />
    }
  ])

  return (
<>

<RouterProvider router={router}>
<Navbar />
</RouterProvider>
</>
  )
}
export default App
