import React, { useState, useRef } from 'react'
import emailjs from 'emailjs-com'
import ReCAPTCHA from 'react-google-recaptcha'

import Navbar from './Navbar'
import Footer from './Footer'

export default function Contact () {
  const form = useRef()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [captchaValue, setCaptchaValue] = useState('')

  const handleNameChange = (event) => setName(event.target.value)
  const handleEmailChange = (event) => setEmail(event.target.value)
  const handleMessageChange = (event) => setMessage(event.target.value)
  const handleCaptcha = (value) => setCaptchaValue(value)
  const handleSubmit = (event) => {
    event.preventDefault()

    if (!captchaValue) {
      alert('Please verify that you are not a robot!')
      return
    }

    emailjs
      .sendForm(
        'service_vjgcpki',
        'template_1fti0al',
        form.current,
        'Lcr9yZALCQjto2Vdl'
      )
      .then(
        (result) => {
          console.log(result.text)
          alert('Your message has been sent successfully!')
        },
        (error) => {
          console.log(error.text)
          alert('There was an error sending your message. Please try again.')
        }
      )
    setName('')
    setEmail('')
    setMessage('')
    setCaptchaValue('')
  }

  return (<>

    <div className="sticky top-0 z-20 bg-black">
    <Navbar/>
    </div>

    <div className="absolute lg:top-[10%] lg:ml-32 sm:m-auto rounded-xl bg-black bg-opacity-50 p-2 z-10">
    <h1 className="text-3xl text-white font-bold">Contact Us</h1>
    </div>
    <div className="z-0">
    <img src="./comp1a.png" alt="server_room"/>
    </div>

    <div className="flex flex-col justify-center items-center my-20">
<h1 className="text-3xl font-extrabold">Hello!</h1>
<p className="md:mx-auto lg:mx-[30%] my-10 text-xl">Thank you for reaching out to us! We appreciate your interest in our services and one of our team members will get back to you as soon as possible.</p>
      <form ref={form} onSubmit={handleSubmit} className="max-w-lg">
      <input type="hidden" name="website" value="reactantTech" />
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="name"
              type="text"
              name="user_name"
              placeholder="Enter your name"
              value={name}
              onChange={handleNameChange}
              required
            />
          </div>
          <div className="w-full px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="email"
              type="email"
              placeholder="Enter your email"
              name="user_email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
                id="message"
                name="message"
                placeholder="Enter your message"
                value={message}
                onChange={handleMessageChange}
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray" />
              </div>

            <div className="mx-4">
            <ReCAPTCHA sitekey="6LeilNwlAAAAACvwzPgtf0blMhBbT23PNmDe5hLv" onChange={handleCaptcha} />
          </div>

          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-auto"
            type="submit"
          >
            Send Message</button>
              </div>
          </form>
    </div>

    <Footer/>
    </>)
}
